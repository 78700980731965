@import "@styles-v2/common";

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 64px 0;

  &.wide {
    padding: 16px 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 56px 0;
  }

  @include media-breakpoint-down(md) {
    padding: 48px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 32px 0;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    flex-flow: column;
  }
}

.title {
  font-size: 48px;
  font-weight: $font-weight-medium;
  color: c(gray-900);
  line-height: 1.25;

  &.wide {
    font-size: 24px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 40px;
  }

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 32px;
  }
}

.right {
  @include media-breakpoint-down(md) {
    padding-top: 16px;
  }
}
